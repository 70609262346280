import { jsx as _jsx } from "react/jsx-runtime";
import { NavBar } from '@frontend/design-system/components/NavBar/NavBar';
import { OptimizeBoldIcon, OptimizeIcon, OrganizeBoldIcon, OrganizeIcon, OrgSettingsBoldIcon, OrgSettingsIcon, PrioritizeBoldIcon, PrioritizeIcon, SupportBoldIcon, SupportIcon, SystemBoldIcon, SystemIcon, } from '@frontend/design-system/theme/icons';
import { isLocalAdminPlus } from '@frontend/domain/contexts/Authorization/Roles';
import { useAppAccess } from '@frontend/domain/contexts/Authorization/UserAccess';
import { useNavigationPermissions } from '@frontend/domain/contexts/Authorization/UserPermission';
import { useOrganizationSummaries } from '@frontend/domain/contexts/Organization/OrganizationSummaryContext';
import { domainReports } from '@frontend/domain/contexts/Reports/ReportsProvider';
import { useCurrentUser } from '@frontend/domain/contexts/User/UserContext';
import { IAppNames } from '@frontend/domain/models/App';
import { AppRoutes } from '@frontend/shared/src/components/Routing/RouteConstants';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { environment } from '../../config/environment';
import LogoFull from '../../images/logoFull.png';
import LogoSmall from '../../images/LogoSmall.png';
import { usePlatformOrgApi } from '../../services/api/platformOrgApiHook';
export var NavLinkName;
(function (NavLinkName) {
    NavLinkName["ORGANIZE"] = "Organize";
    NavLinkName["PRIORITIZE"] = "Prioritize";
    NavLinkName["OPTIMIZE"] = "Optimize";
    NavLinkName["SYSTEM_ADMIN"] = "System Admin";
    NavLinkName["ORGANIZATION_SETTINGS"] = "Organization Settings";
    NavLinkName["SUPPORT"] = "Support";
})(NavLinkName || (NavLinkName = {}));
export const ExpandableSidebar = ({ children, }) => {
    var _a, _b;
    const { selectedOrganizationIdentity } = useOrganizationSummaries();
    const { user } = useCurrentUser();
    const { canSeeAdminPage } = useNavigationPermissions();
    const { hasAccess: hasPIQAccess } = useAppAccess(IAppNames.PIQ);
    const { hasAccess: hasReportsAccess } = useAppAccess(IAppNames.REPORTS);
    const { userReports } = usePlatformOrgApi();
    const [reports, setReports] = useState();
    const location = useLocation();
    const currentURL = location.pathname;
    useEffect(() => {
        if (selectedOrganizationIdentity) {
            userReports(selectedOrganizationIdentity).then((reports) => {
                setReports(domainReports(reports));
            });
        }
    }, [selectedOrganizationIdentity]);
    const isSublinkCurrent = (sublinkURL) => {
        return currentURL === sublinkURL;
    };
    const mapSublinks = (sublinks) => {
        return sublinks.map((sublink) => {
            var _a, _b;
            return (Object.assign(Object.assign({}, sublink), { isCurrentLink: isSublinkCurrent((_b = (_a = sublink.link) === null || _a === void 0 ? void 0 : _a.href) !== null && _b !== void 0 ? _b : '') }));
        });
    };
    const spreadReports = (orgIdentity, reportList) => {
        return reportList && reportList.length > 0 && hasReportsAccess
            ? [
                {
                    title: 'Reports',
                    subGroups: reportList.map((report) => {
                        const reportRoute = AppRoutes.reports(orgIdentity, report.id);
                        return {
                            title: report.name,
                            link: {
                                href: reportRoute,
                            },
                        };
                    }),
                },
            ]
            : [];
    };
    const externalBaseRoutes = {
        organize: environment.fcaUrl,
        prioritize: environment.plannerUrl,
        // TODO: add to configuration
        opportunities: `${environment.rootPathUrl}/prioritize/opportunities`,
    };
    return (_jsx(NavBar, { squishContent: true, user: {
            name: (_a = `${user === null || user === void 0 ? void 0 : user.firstName} ${user === null || user === void 0 ? void 0 : user.lastName}`) !== null && _a !== void 0 ? _a : '',
            systemRole: (_b = user === null || user === void 0 ? void 0 : user.systemRole) !== null && _b !== void 0 ? _b : '',
        }, assets: {
            logoSmallSrc: LogoSmall,
            logoLargeSrc: LogoFull,
        }, links: {
            home: {
                href: AppRoutes.landingPage(selectedOrganizationIdentity),
            },
            profile: {
                href: AppRoutes.profile(),
            },
            logout: {
                href: AppRoutes.logout(),
            },
        }, linkGroups: [
            {
                title: NavLinkName.ORGANIZE,
                icon: OrganizeIcon,
                selectedIcon: OrganizeBoldIcon,
                subLinks: mapSublinks([
                    {
                        title: 'myFacilities',
                        link: {
                            href: selectedOrganizationIdentity
                                ? AppRoutes.myFacilities(selectedOrganizationIdentity, externalBaseRoutes.organize)
                                : AppRoutes.landingPage(undefined, externalBaseRoutes.organize),
                            external: true,
                        },
                    },
                    {
                        title: 'myGallery',
                        link: {
                            href: selectedOrganizationIdentity
                                ? AppRoutes.myGallery(selectedOrganizationIdentity, externalBaseRoutes.organize)
                                : AppRoutes.landingPage(undefined, externalBaseRoutes.organize),
                            external: true,
                        },
                    },
                    ...(selectedOrganizationIdentity
                        ? [
                            ...spreadReports(selectedOrganizationIdentity, reports === null || reports === void 0 ? void 0 : reports.organize),
                        ]
                        : []),
                ]),
            },
            {
                title: NavLinkName.PRIORITIZE,
                icon: PrioritizeIcon,
                selectedIcon: PrioritizeBoldIcon,
                subLinks: [
                    {
                        title: 'myProjects',
                        link: {
                            href: selectedOrganizationIdentity
                                ? AppRoutes.myProjects(selectedOrganizationIdentity, externalBaseRoutes.prioritize)
                                : AppRoutes.landingPage(undefined, externalBaseRoutes.prioritize),
                            external: true,
                        },
                    },
                    {
                        title: 'myScenarios',
                        link: {
                            href: selectedOrganizationIdentity
                                ? AppRoutes.myScenarios(selectedOrganizationIdentity, externalBaseRoutes.prioritize)
                                : AppRoutes.landingPage(undefined, externalBaseRoutes.prioritize),
                            external: true,
                        },
                    },
                    {
                        title: 'myAssets',
                        link: {
                            href: selectedOrganizationIdentity
                                ? AppRoutes.myAssets(selectedOrganizationIdentity, externalBaseRoutes.prioritize)
                                : AppRoutes.landingPage(undefined, externalBaseRoutes.prioritize),
                            external: true,
                        },
                    },
                    ...(hasPIQAccess
                        ? [
                            {
                                title: 'myOpportunities',
                                link: {
                                    href: AppRoutes.myOpportunities(selectedOrganizationIdentity, externalBaseRoutes.opportunities),
                                    external: true,
                                },
                            },
                        ]
                        : []),
                    ...(selectedOrganizationIdentity
                        ? [
                            ...spreadReports(selectedOrganizationIdentity, reports === null || reports === void 0 ? void 0 : reports.prioritize),
                        ]
                        : []),
                ],
            },
            {
                title: NavLinkName.OPTIMIZE,
                icon: OptimizeIcon,
                selectedIcon: OptimizeBoldIcon,
                subLinks: selectedOrganizationIdentity
                    ? [
                        ...spreadReports(selectedOrganizationIdentity, reports === null || reports === void 0 ? void 0 : reports.optimize),
                    ]
                    : [],
                divideAfter: true,
            },
            ...(canSeeAdminPage
                ? [
                    {
                        title: NavLinkName.SYSTEM_ADMIN,
                        icon: SystemIcon,
                        selectedIcon: SystemBoldIcon,
                        link: {
                            href: AppRoutes.systemAdmin(),
                        },
                    },
                ]
                : []),
            ...(selectedOrganizationIdentity &&
                isLocalAdminPlus(user, selectedOrganizationIdentity)
                ? [
                    {
                        title: NavLinkName.ORGANIZATION_SETTINGS,
                        icon: OrgSettingsIcon,
                        selectedIcon: OrgSettingsBoldIcon,
                        link: {
                            href: AppRoutes.organizationSettings(selectedOrganizationIdentity),
                        },
                    },
                ]
                : []),
            {
                title: NavLinkName.SUPPORT,
                icon: SupportIcon,
                selectedIcon: SupportBoldIcon,
                link: {
                    href: AppRoutes.support(),
                },
            },
        ], children: children }));
};
