import { FacilityDetailResponseBuilder, FacilitySummaryResponseBuilder, IConfigureFacilityBuilder, } from '../testing/facilityMocks';
import { createGetHandler, createPostHandler, } from '../testing/msw/handlerUtils';
const facilityEndpoints = {
    load: '/api/v1/Facility/load/:facilityId',
    list: '/api/v1/Facility/list/:organizationId',
    configure: '/api/v1/Facility/configure',
};
const facilityLoadHandler = createGetHandler(facilityEndpoints.load, new FacilityDetailResponseBuilder({}).build());
const facilityListHandler = createGetHandler(facilityEndpoints.list, [
    new FacilitySummaryResponseBuilder({}).build(),
]);
const facilityConfigureHandler = createPostHandler(facilityEndpoints.configure, [new IConfigureFacilityBuilder({}).build()]);
const facilityHandlers = [
    facilityLoadHandler,
    facilityListHandler,
    facilityConfigureHandler,
];
export const facilityHandler = {
    endpoints: facilityEndpoints,
    handlers: facilityHandlers,
};
