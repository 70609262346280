import { createGetHandler, createPostHandler, } from '../testing/msw/handlerUtils';
import { OrganizationDetailsResponseBuilder, OrganizationSummaryResponseBuilder, } from '../testing/platformOrganizationMocks';
const organizationEndpoints = {
    list: '/api/v1/Organization/list',
    configure: '/api/v1/Organization/configure',
    initialize: '/api/v1/Organization/initialize',
};
const organizationListHandler = createGetHandler(organizationEndpoints.list, [
    new OrganizationSummaryResponseBuilder({}).build(),
]);
const organizationConfigureHandler = createPostHandler(organizationEndpoints.configure, [new OrganizationDetailsResponseBuilder({}).build()]);
const organizationInitializeHandler = createGetHandler(organizationEndpoints.initialize, {});
const organizationHandlers = [
    organizationListHandler,
    organizationConfigureHandler,
    organizationInitializeHandler,
];
export const organizationHandler = {
    endpoints: organizationEndpoints,
    handlers: organizationHandlers,
};
