export class FacilityService {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Adds or updates a new facility.
     * @param formData
     * @returns FacilityDetailResponse OK
     * @throws ApiError
     */
    postApiV1FacilityConfigure(formData) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/Facility/configure',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * Imports csv of bulk facilities into a specified organization
     * @param formData
     * @returns any OK
     * @throws ApiError
     */
    postApiV1FacilityImport(formData) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/Facility/import',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * Returns the full details of a facility
     * @param facilityId ID of the facility
     * @returns FacilityDetailResponse OK
     * @throws ApiError
     */
    getApiV1FacilityLoad(facilityId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/Facility/load/{facilityId}',
            path: {
                facilityId: facilityId,
            },
            errors: {
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * Returns summary information about facilities in an organization
     * @param organizationId ID of the organization to return facilities for
     * @returns FacilitySummaryResponse OK
     * @throws ApiError
     */
    getApiV1FacilityList(organizationId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/Facility/list/{organizationId}',
            path: {
                organizationId: organizationId,
            },
            errors: {
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * Hard deletes a facility and all of its data.
     * @param facilityId ID of the facility to delete
     * @returns any OK
     * @throws ApiError
     */
    deleteApiV1FacilityRemove(facilityId) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/v1/Facility/remove/{facilityId}',
            path: {
                facilityId: facilityId,
            },
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * Exports the floor plans, spaces, and assets for a facility.
     * Returns the individual CSVs for the plans, spaces, and assets in the facility as a zip file.
     * Filename will be in the content-disposition header.
     * @param requestBody IDs of the facility and organization to export
     * @returns binary OK
     * @throws ApiError
     */
    postApiV1FacilityExport(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/Facility/export',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * Returns the system data necessary to create or edit a facility.
     * @param organizationId ID of the organization the facility belongs to
     * @returns FacilityTemplateResponse OK
     * @throws ApiError
     */
    getApiV1FacilityInitialize(organizationId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/Facility/initialize/{organizationId}',
            path: {
                organizationId: organizationId,
            },
            errors: {
                403: `Forbidden`,
            },
        });
    }
}
