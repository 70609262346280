import { faker } from '@faker-js/faker';
import { IAppNames } from '../models/App';
import { IVerticalMarketNames, } from '../models/Organization/OrganizationInterfaces';
export class OrganizationSummaryResponseBuilder {
    constructor(overrides) {
        this.build = () => {
            return Object.assign({ organizationIdentity: faker.number.int({
                    min: undefined,
                    max: undefined,
                }), name: faker.lorem.slug(1), logoUrl: faker.internet.url(), primaryContact: {
                    userId: faker.lorem.slug(1),
                    userIdentity: faker.number.int({
                        min: undefined,
                        max: undefined,
                    }),
                    firstName: faker.lorem.slug(1),
                    lastName: faker.lorem.slug(1),
                    emailAddress: faker.lorem.slug(1),
                }, apps: [] }, this.overrides);
        };
        this.overrides = overrides;
    }
}
export class OrganizationDetailsResponseBuilder {
    constructor(overrides) {
        this.build = () => {
            return Object.assign({ organizationIdentity: faker.number.int(), name: faker.lorem.slug(1), businessUnit: faker.lorem.slug(1), companyWebsite: faker.internet.url(), logo: {
                    logoFileName: faker.lorem.slug(1),
                    logoUrl: faker.internet.url(),
                }, verticalMarket: faker.helpers.enumValue(IVerticalMarketNames), primaryContact: {
                    userId: faker.lorem.slug(1),
                    userIdentity: faker.number.int({
                        min: undefined,
                        max: undefined,
                    }),
                    firstName: faker.lorem.slug(1),
                    lastName: faker.lorem.slug(1),
                    emailAddress: faker.lorem.slug(1),
                }, apps: [], userCount: faker.number.int() }, this.overrides);
        };
        this.overrides = overrides;
    }
}
export class IOrganizationSnapshotBuilder {
    constructor(overrides) {
        this.build = () => {
            return Object.assign({ identity: faker.number.int({
                    min: undefined,
                    max: undefined,
                }), name: faker.lorem.slug(1) }, this.overrides);
        };
        this.overrides = overrides;
    }
}
export class IOrganizationSummaryBuilder {
    constructor(overrides) {
        this.build = () => {
            return Object.assign({ identity: faker.number.int({
                    min: undefined,
                    max: undefined,
                }), name: faker.lorem.slug(1), primaryContact: {
                    identity: faker.number.int({
                        min: undefined,
                        max: undefined,
                    }),
                    firstName: faker.lorem.slug(1),
                    lastName: faker.lorem.slug(1),
                    email: faker.lorem.slug(1),
                }, apps: [] }, this.overrides);
        };
        this.addApp = (overrides) => {
            const app = new IAppBuilder(overrides).build();
            if (this.overrides.apps) {
                this.overrides.apps.push(app);
            }
            else {
                this.overrides.apps = [app];
            }
            return this;
        };
        this.overrides = overrides;
    }
}
export class IAppBuilder {
    constructor(overrides) {
        this.build = () => {
            return Object.assign({ app: faker.helpers.arrayElement([
                    IAppNames.FCA,
                    IAppNames.PIQ,
                    IAppNames.PLANNER,
                    IAppNames.REPORTS,
                ]), enabled: true }, this.overrides);
        };
        this.overrides = overrides;
    }
}
