import { faker } from '@faker-js/faker';
import { ILocationBuilder } from './locationMocks';
export class FacilityDetailResponseBuilder {
    constructor(overrides) {
        this.build = () => {
            return Object.assign({ facilityId: faker.number.int({ min: undefined, max: undefined }), name: faker.lorem.slug(1), siteName: faker.lorem.slug(1), location: {
                    address1: faker.location.streetAddress(),
                    address2: faker.location.secondaryAddress(),
                    city: faker.location.city(),
                    stateOrProvince: faker.location.state(),
                    postalCode: faker.location.zipCode(),
                    latitude: faker.location.latitude(),
                    longitude: faker.location.longitude(),
                }, yearBuilt: faker.date.past().getFullYear(), primaryFacilityType: faker.lorem.slug(1), secondaryFacilityType: faker.lorem.slug(1), tertiaryFacilityType: faker.lorem.slug(1), originalSquareFootage: faker.number.int({ min: 1, max: 300000 }), keyCount: faker.number.int({ min: 1, max: 3000 }), unitCount: faker.number.int({ min: 1, max: 3000 }), customerInternalId: faker.lorem.slug(2), propertyManager: faker.person.fullName(), hasPropertySurvey: faker.datatype.boolean(), hasCompletedAudit: faker.datatype.boolean(), facilityGrouper1: faker.lorem.slug(1), facilityGrouper2: faker.lorem.slug(1), ownershipType: faker.helpers.arrayElement([
                    'Lease',
                    'Own',
                    'Co-own',
                ]), photo: {
                    fileName: faker.lorem.slug(1),
                    photoUrl: faker.image.url(),
                }, additions: [], enrollmentYears: [] }, this.overrides);
        };
        this.overrides = overrides;
    }
    addAdditions(additionOverrides) {
        const addition = new FacilityAdditionResponseBuilder(additionOverrides).build();
        if (this.overrides.additions) {
            this.overrides.additions.push(addition);
        }
        else {
            this.overrides.additions = [addition];
        }
        return this;
    }
    addEnrollment(enrollmentOverrides) {
        const enrollment = new FacilityEnrollmentResponseBuilder(enrollmentOverrides).build();
        if (this.overrides.enrollmentYears) {
            this.overrides.enrollmentYears.push(enrollment);
        }
        else {
            this.overrides.enrollmentYears = [enrollment];
        }
        return this;
    }
}
export class FacilitySummaryResponseBuilder {
    constructor(overrides) {
        this.build = () => {
            return Object.assign({ facilityId: faker.number.int({ min: undefined, max: undefined }), name: faker.lorem.slug(1), siteName: faker.lorem.slug(1), location: {
                    address1: faker.location.streetAddress(),
                    address2: faker.location.secondaryAddress(),
                    city: faker.location.city(),
                    stateOrProvince: faker.location.state(),
                    postalCode: faker.location.zipCode(),
                    latitude: faker.location.latitude(),
                    longitude: faker.location.longitude(),
                }, isRemovable: faker.datatype.boolean(), photoUrl: faker.lorem.slug(1), totalSize: faker.number.int({ min: 0, max: undefined }), sizeUnits: faker.lorem.slug(1), latestEnrolledCount: faker.number.int({ min: 0, max: 3000 }), yearBuilt: faker.date.past().getFullYear(), primaryFacilityType: faker.lorem.slug(1) }, this.overrides);
        };
        this.overrides = overrides;
    }
}
export class FacilityAdditionResponseBuilder {
    constructor(overrides) {
        this.build = () => {
            return Object.assign({ additionId: faker.number.int({ min: undefined, max: undefined }), yearBuilt: faker.date.past().getFullYear(), squareFootage: faker.number.int({ min: undefined, max: 300000 }) }, this.overrides);
        };
        this.overrides = overrides;
    }
}
export class FacilityEnrollmentResponseBuilder {
    constructor(overrides) {
        this.build = () => {
            return Object.assign({ schoolYear: `${faker.date.past().getFullYear()}-${faker.date.past().getFullYear() + 1}`, gradeEnrollment: [
                    {
                        gradeLevel: faker.lorem.slug(1),
                        count: faker.number.int({
                            min: undefined,
                            max: undefined,
                        }),
                    },
                ] }, this.overrides);
        };
        this.overrides = overrides;
    }
}
export class IFacilityBuilder {
    constructor(overrides) {
        this.build = () => {
            return Object.assign({ identity: faker.number.int({ min: undefined, max: undefined }), name: faker.lorem.slug(1), siteName: faker.lorem.slug(1), photoName: faker.lorem.slug(1), photoUrl: faker.image.url(), address1: faker.location.streetAddress(), address2: faker.location.secondaryAddress(), city: faker.location.city(), stateOrProvince: faker.location.state(), postalCode: faker.location.zipCode(), latitude: faker.location.latitude(), longitude: faker.location.longitude(), yearBuilt: faker.date.past().getFullYear(), primaryFacilityType: faker.lorem.slug(1), secondaryFacilityType: faker.lorem.slug(1), tertiaryFacilityType: faker.lorem.slug(1), originalSquareFootage: faker.number.int({ min: 1, max: 300000 }), keyCount: faker.number.int({ min: 1, max: 3000 }), unitCount: faker.number.int({ min: 1, max: 3000 }), customerInternalId: faker.lorem.slug(2), propertyManager: faker.person.fullName(), hasPropertySurvey: faker.helpers.arrayElement(['Yes', 'No']), hasCompletedAudit: faker.helpers.arrayElement(['Yes', 'No']), facilityGrouper1: faker.lorem.slug(1), facilityGrouper2: faker.lorem.slug(1), ownershipType: faker.helpers.arrayElement([
                    'Lease',
                    'Own',
                    'Co-own',
                ]), additions: [], enrollment: [], propertySize: {
                    type: faker.helpers.arrayElement([
                        'Sq Ft',
                        '# Keys',
                        '# Units',
                    ]),
                    value: faker.number.int({ min: 0, max: 3000 }),
                } }, this.overrides);
        };
        this.overrides = overrides;
    }
}
export class IConfigureFacilityBuilder {
    constructor(overrides) {
        this.build = () => {
            return Object.assign({ name: faker.lorem.slug(1), siteName: faker.lorem.slug(1), facilityPhoto: {
                    file: new File([], 'test.png'),
                    fileName: 'test.png',
                }, address1: faker.location.streetAddress(), address2: faker.location.secondaryAddress(), city: faker.location.city(), stateOrProvince: faker.location.state(), postalCode: faker.location.zipCode(), yearBuilt: faker.date.past().getFullYear(), primaryFacilityType: faker.lorem.slug(1), secondaryFacilityType: faker.lorem.slug(1), tertiaryFacilityType: faker.lorem.slug(1), originalSquareFootage: faker.number.int({ min: 1, max: 300000 }), keyCount: faker.number.int({ min: 1, max: 3000 }), unitCount: faker.number.int({ min: 1, max: 3000 }), customerInternalId: faker.lorem.slug(2), propertyManager: faker.person.fullName(), hasPropertySurvey: faker.helpers.arrayElement(['Yes', 'No']), hasCompletedAudit: faker.helpers.arrayElement(['Yes', 'No']), facilityGrouper1: faker.lorem.slug(1), facilityGrouper2: faker.lorem.slug(1), ownershipType: faker.helpers.arrayElement([
                    'Lease',
                    'Own',
                    'Co-own',
                ]), additions: [], enrollment: [] }, this.overrides);
        };
        this.overrides = overrides;
    }
}
export class IFacilitySummaryBuilder {
    constructor(overrides) {
        this.build = () => {
            return Object.assign({ identity: faker.number.int({ min: undefined, max: undefined }), name: faker.lorem.slug(1), siteName: faker.lorem.slug(1), location: new ILocationBuilder({}).build(), isRemovable: true, photoUrl: faker.image.url(), totalSquareFootage: faker.number.int({ min: 0, max: 10000000 }), latestEnrolledCount: faker.number.int({ min: 0, max: 100000 }), yearBuilt: faker.date.past().getFullYear() }, this.overrides);
        };
        this.overrides = overrides;
    }
}
export class IFacilityTemplateBuilder {
    constructor(overrides) {
        this.build = () => {
            return Object.assign({ primaryFacilityTypes: [
                    {
                        identity: faker.number.int({
                            min: undefined,
                            max: undefined,
                        }),
                        name: faker.lorem.slug(1),
                    },
                ], secondaryFacilityTypes: [
                    {
                        identity: faker.number.int({
                            min: undefined,
                            max: undefined,
                        }),
                        name: faker.lorem.slug(1),
                    },
                ], tertiaryFacilityTypes: [
                    {
                        identity: faker.number.int({
                            min: undefined,
                            max: undefined,
                        }),
                        name: faker.lorem.slug(1),
                    },
                ], schoolYears: [faker.lorem.slug(1)], gradeLevels: [faker.lorem.slug(1)], ownershipTypes: [faker.lorem.slug(1)], importTemplateUrl: faker.lorem.slug(1), siteNames: [faker.lorem.slug(1)] }, this.overrides);
        };
        this.overrides = overrides;
    }
}
