import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { AddIcon } from '@chakra-ui/icons';
import { Button, Stack, Table, Tbody, Td, Thead, Tr, useDisclosure, } from '@chakra-ui/react';
import { variants } from '@frontend/design-system/theme/theme';
import { UserManagementContext } from '@frontend/domain/contexts/Authorization/Roles';
import { convertIOrganizationDetailsToIOrganizationSummary } from '@frontend/domain/models/Organization/OrganizationConversions';
import { useState } from 'react';
import { DeleteModal } from '../../../sharedComponents/DeleteModal';
import { deleteDescription, deleteUserTitle, useDeleteUser, } from '../../../utils/handleDelete';
import { canDeleteUser } from '../../../utils/userRoleUtils';
import InvitationSentModal from '../../SystemAdmin/InvitationSentModal';
import ResendInvitationModal from '../../SystemAdmin/ResendInvitationModal';
import { AddUserModal as AddUserModalNew } from '../../SystemAdmin/UserManagement/AddUserModal';
import ActionsBodyUserModal from './ActionsBodyUserModal';
import { UsersTableRow } from './UsersTableRow';
export const UsersTable = ({ org, orgUsers, getOrgUsersList, canEditOrgUser, canAddOrgUser, currentUser, canResendInvite, }) => {
    const [user, setUser] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const canResendInvitation = canResendInvite(UserManagementContext.ORG, org === null || org === void 0 ? void 0 : org.identity);
    const handleDeleteUser = useDeleteUser();
    const { isOpen: isOpenAddUser, onOpen: onOpenAddUser, onClose: onCloseAddUser, } = useDisclosure();
    const { isOpen: isOpenResendInvitation, onClose: onCloseResendInvitation } = useDisclosure({ defaultIsOpen: true });
    const { isOpen: isOpenInvitationSent, onOpen: onOpenInvitationSent, onClose: onCloseInvitationSent, } = useDisclosure();
    const { isOpen: isOpenDeleteUser, onOpen: onOpenDeleteUser, onClose: onCloseDeleteUser, } = useDisclosure();
    const onAddUserModalClose = () => {
        setUser(undefined);
        onCloseAddUser();
    };
    const onEnd = () => {
        onCloseDeleteUser();
        setUser(undefined);
        getOrgUsersList();
    };
    const renderRows = () => {
        return (_jsx(_Fragment, { children: orgUsers &&
                orgUsers.map((user) => {
                    var _a;
                    const canDelete = canDeleteUser(currentUser, user);
                    const orgRole = (_a = user.organizationRoles) === null || _a === void 0 ? void 0 : _a.find((orgRole) => orgRole.organizationIdentity === (org === null || org === void 0 ? void 0 : org.identity));
                    // TODO: for some reason it's rerendering this a LOT, like 7x
                    return (_jsx(UsersTableRow, { user: user, org: org, orgRole: orgRole, popoverMenu: _jsx(ActionsBodyUserModal, { user: user, canDelete: canDelete, onOpenAddUser: onOpenAddUser, canResendInvitation: canResendInvitation, setIsLoading: setIsLoading, onOpenInvitationSent: onOpenInvitationSent, onCloseInvitationSent: onCloseInvitationSent, onCloseResendInvitation: onCloseResendInvitation, setUser: setUser, onOpenDelete: onOpenDeleteUser }), canEditOrgUser: canEditOrgUser, canAddOrgUser: canAddOrgUser }, user.id));
                }) }));
    };
    return (_jsxs(Stack, { children: [canAddOrgUser && (_jsx(Button, { onClick: onOpenAddUser, w: '205px', h: '43px', variant: variants.addNewBtn, leftIcon: _jsx(AddIcon, {}), children: "Add New User" })), _jsxs(Table, { children: [_jsx(Thead, { layerStyle: 'thead', children: _jsxs(Tr, { children: [_jsx(Td, { children: "Name" }), _jsx(Td, { children: "Job Title" }), _jsx(Td, { children: "Email" }), _jsx(Td, { children: "Apps" }), canEditOrgUser && _jsx(Td, { children: "Actions" })] }) }), _jsx(Tbody, { children: renderRows() })] }), _jsx(AddUserModalNew, { isOpen: isOpenAddUser, onClose: onAddUserModalClose, availableOrgs: org
                    ? [
                        convertIOrganizationDetailsToIOrganizationSummary(org),
                    ]
                    : [], originalUser: user, getOrgUsersList: getOrgUsersList, currentUser: currentUser }), user && user.email && isLoading && (_jsx(ResendInvitationModal, { email: user.email, isOpen: isOpenResendInvitation, onClose: onCloseResendInvitation })), _jsx(InvitationSentModal, { isOpen: isOpenInvitationSent, onClose: onCloseInvitationSent }), user && (_jsx(DeleteModal, { isOpen: isOpenDeleteUser, onClose: onCloseDeleteUser, deleteItem: () => handleDeleteUser(user, onEnd, org), title: deleteUserTitle(user), description: deleteDescription((user === null || user === void 0 ? void 0 : user.firstName) + ' ' + (user === null || user === void 0 ? void 0 : user.lastName)) }))] }));
};
