import { IVerticalMarketNames, } from '@frontend/domain/models/Organization/OrganizationInterfaces';
import { FacilityRoleEnum, SystemRoleEnum, } from '../../objects/UserData';
export const defaultUser = {
    id: 'user_id',
    firstName: '',
    lastName: '',
    email: '',
    jobTitle: '',
    phoneNumber: '',
    isDeleted: false,
    organizationRoles: [],
    systemRole: SystemRoleEnum.USER,
    userIdentity: 1,
    apps: [],
    facilityRoles: [],
};
export const defaultUser2 = {
    id: 'user_id_2',
    firstName: '',
    lastName: '',
    email: '',
    jobTitle: '',
    phoneNumber: '',
    isDeleted: false,
    organizationRoles: [],
    systemRole: SystemRoleEnum.USER,
    userIdentity: 2,
    apps: [],
    facilityRoles: [],
};
export const defaultAddress = {
    address1: '',
    city: '',
    zip: '',
    state: '',
};
export const defaultFacilityAddress = {
    address1: '',
    address2: '',
    city: '',
    zip: '',
    state: '',
};
export const defaultSiteAddress = {
    address1: '',
    city: '',
    zip: '',
    state: '',
};
export const defaultPrioritizationWeight = {
    identity: 1,
    name: 'prioritizationWeight',
    type: 'type',
    weight: 100,
};
export const defaultProjectType = {
    identity: 1,
    name: 'projectType',
    algorithmId: 1,
    isInUse: false,
};
export const defaultFundingType = {
    identity: 1,
    name: 'fundingType',
    isInUse: false,
};
export const defaultPrimaryContact = {
    identity: 1,
    firstName: 'Test',
    lastName: 'Test',
    email: 'test@gmail.com',
};
export const defaultOrgSummary = {
    identity: 1,
    name: 'default org',
    primaryContact: defaultPrimaryContact,
    apps: [],
};
export const defaultOrgDetails = {
    identity: 1,
    name: 'default org',
    businessUnit: '',
    verticalMarket: 'Commercial Real Estate',
    logo: {
        name: '',
        url: '',
    },
    apps: [],
    companyWebsite: '',
    primaryContact: defaultPrimaryContact,
    sites: [],
    userCount: 1,
};
/** Should no longer be used in testing, use Builders instead */
export const defaultOrg = {
    id: 'org_id',
    orgIdentity: 1,
    name: 'default org',
    businessUnit: '',
    verticalMarket: IVerticalMarketNames.COMMERCIAL_REAL_ESTATE,
    primaryContact: defaultUser,
    clientConcierge: defaultUser,
    primaryContactId: defaultUser.id,
    primaryContactIdentity: defaultUser.userIdentity,
    clientConciergeId: defaultUser.id,
    clientConciergeIdentity: defaultUser.userIdentity,
    address: defaultAddress,
    companyWebsite: '',
    smallLogoUrl: undefined,
    largeLogoUrl: undefined,
    users: [],
    isDeleted: false,
    sites: [],
    contacts: [],
    fundingTypes: [],
    projectTypes: [],
    facilityTypes: [],
    prioritizationWeights: [],
    escalationFactors: [],
};
export const defaultFacilityAddition = {
    id: 'addition_id',
    additionName: '',
    additionSquareFootage: 0,
    facilityYear: 0,
};
export const defaultFacilityRole = {
    facilityIdentity: 1,
    role: FacilityRoleEnum.MEMBER,
};
export const defaultSite = {
    id: 'site_id',
    name: '',
    address: defaultSiteAddress,
    isDeleted: false,
    facilities: [],
    totalSqFt: 0,
};
export const defaultReport = {
    reportId: 1,
    organizationId: 1,
    organizationKey: 'org_key',
    workspaceKey: '1FA85F64-5717-4562-B3FC-2C963F66AFA6',
    reportKey: '1FA85F64-5717-4562-B3FC-2C963F66AFA6',
    reportName: 'Test',
    isPublished: true,
    appContext: 'Organize',
    isCustom: false,
    lastUpdated: '2023-06-13T19:12:11.6563005',
};
export const testReport1 = {
    reportId: 2,
    organizationId: 1,
    organizationKey: 'org_key',
    workspaceKey: '1FA85F64-5717-4562-B3FC-2C963F66AFA6',
    reportKey: '1FA85F64-5717-4562-B3FC-2C963F66AFA7',
    reportName: 'Test Report 2',
    isPublished: true,
    appContext: 'Organize',
    isCustom: true,
    lastUpdated: '2023-06-13T19:12:11.6563005',
};
