import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { IconButton, Popover, PopoverBody, PopoverContent, PopoverTrigger, Td, Tr, } from '@chakra-ui/react';
import { BsThreeDots } from 'react-icons/bs';
import { appColors } from '../../../config/constants';
import { UserApps } from '../../../sharedComponents/UserApps';
export const UsersTableRow = ({ user, org, popoverMenu, canEditOrgUser, }) => {
    const renderActionTd = () => {
        return (_jsxs(Popover, { placement: 'left', children: [_jsx(PopoverTrigger, { children: _jsx(IconButton, { boxSize: '25px', icon: _jsx(BsThreeDots, {}), "aria-label": 'More Menu', variant: 'ghost' }) }), _jsx(PopoverContent, { width: '150px', border: '1px solid', borderColor: appColors.BORDER_SEC_LIGHT_GRAY, boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.15)', bg: appColors.BACKGROUND_LIGHT_GRAY, children: _jsx(PopoverBody, { children: popoverMenu }) })] }));
    };
    return (_jsx(_Fragment, { children: _jsxs(Tr, { h: '50px', boxSizing: 'border-box', children: [_jsxs(Td, { py: 0, children: [user.firstName, " ", user.lastName] }), _jsx(Td, { py: 0, children: user.jobTitle }), _jsx(Td, { py: 0, children: user.email }), _jsx(Td, { py: 0, children: _jsx(UserApps, { user: user, org: org }) }), canEditOrgUser && _jsx(Td, { py: 0, children: renderActionTd() })] }) }));
};
