var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { convertBlobToFile } from '../conversion/GeneralConversions';
import { usePlatformResponseWrapper } from '../hooks/responseWrappers/usePlatformResponseWrapper';
import { convertFacilityDetailResponseToIFacility, convertFacilitySummaryResponseToIFacilitySummary, convertFacilityTemplateResponseToIFacilityTemplate, convertIConfigureFacilityToFacilityFormData, } from '../models/Facility/FacilityConversions';
export const useFacilityService = ({ platformApi }) => {
    const { handleResponse: handlePlatformResponse } = usePlatformResponseWrapper();
    const exportCSVFiles = (facilityIdentity, orgIdentity, fileName, type) => __awaiter(void 0, void 0, void 0, function* () {
        return handlePlatformResponse(platformApi.facility.postApiV1FacilityExport({
            facilityId: facilityIdentity,
            organizationId: orgIdentity,
        }), {
            error: { label: 'Error occurred exporting csv files' },
            success: { enabled: false },
        })
            .then((res) => {
            return convertBlobToFile(res, fileName, type);
        })
            .catch(() => undefined);
    });
    const fetchFacility = (facilityIdentity) => __awaiter(void 0, void 0, void 0, function* () {
        return handlePlatformResponse(platformApi.facility.getApiV1FacilityLoad(facilityIdentity), {
            error: { label: 'Error occurred retrieving the facility' },
            success: { enabled: false },
        }).then(convertFacilityDetailResponseToIFacility);
    });
    const fetchFacilityList = (organizationIdentity) => __awaiter(void 0, void 0, void 0, function* () {
        return handlePlatformResponse(platformApi.facility.getApiV1FacilityList(organizationIdentity), {
            error: { label: 'Error occurred retrieving facilities' },
            success: { enabled: false },
        }).then((res) => res.map(convertFacilitySummaryResponseToIFacilitySummary));
    });
    /** This will have to be adjusted when used for facility create/edit as well as PIQ edit
     * Currently IUpdateFacility is only used in PIQ edit
     */
    const configureFacility = (facility, orgIdentity) => __awaiter(void 0, void 0, void 0, function* () {
        const facilityRequest = convertIConfigureFacilityToFacilityFormData(facility, orgIdentity);
        return handlePlatformResponse(platformApi.facility.postApiV1FacilityConfigure(facilityRequest), {
            error: { label: 'Error occurred retrieving Facilities' },
            success: {
                enabled: true,
                label: `Successfully updated Facility: ${facility.name}`,
            },
        }).then(convertFacilityDetailResponseToIFacility);
    });
    /** Hard delete facility and all facility data */
    const deleteFacility = (facilityIdentity) => __awaiter(void 0, void 0, void 0, function* () {
        return handlePlatformResponse(platformApi.facility.deleteApiV1FacilityRemove(facilityIdentity), {
            error: { label: 'Error occurred deleting the Facility' },
            success: {
                enabled: true,
                label: 'Successfully deleted the Facility',
            },
        });
    });
    const fetchFacilityTemplate = (organizationIdentity) => __awaiter(void 0, void 0, void 0, function* () {
        return handlePlatformResponse(platformApi.facility.getApiV1FacilityInitialize(organizationIdentity), {
            error: { label: 'Error occurred initializing facility data' },
            success: {
                enabled: false,
            },
        }).then(convertFacilityTemplateResponseToIFacilityTemplate);
    });
    return {
        facility: {
            fetch: fetchFacility,
            fetchTemplate: fetchFacilityTemplate,
            fetchList: fetchFacilityList,
            upsert: configureFacility,
            delete: deleteFacility,
            exportCSVFiles,
        },
    };
};
