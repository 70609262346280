import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, FormControl, FormLabel, Radio, RadioGroup, Stack, Text, } from '@chakra-ui/react';
import { appColors } from '../../../config/constants';
import { FacilityRoleEnum } from '../../../objects/UserData';
export const AddFacilityRolesToUser = ({ selectedOrgFacilities, currentUserFacilityRoles, updateFacRoles, }) => {
    const facilityRoleButtons = selectedOrgFacilities.map((facility) => {
        var _a;
        const newObj = {
            facilityName: facility.name,
            facilityIdentity: facility.identity,
            facilityRole: (_a = currentUserFacilityRoles.find((facilityUser) => facilityUser.facilityIdentity === facility.identity)) === null || _a === void 0 ? void 0 : _a.role,
        };
        return newObj;
    });
    const onSelectFacilityRole = (facilityRole, facilityRoleButton) => {
        updateFacRoles({
            facilityIdentity: facilityRoleButton.facilityIdentity,
            role: facilityRole,
        });
    };
    const renderRadioGroup = () => {
        return facilityRoleButtons.map((facilityRoleButton) => {
            return (_jsxs(FormControl, { display: 'flex', justifyContent: 'space-between', as: 'fieldset', children: [_jsx(FormLabel, { color: appColors.TEXT_COLOR, fontFamily: 'montserrat', fontSize: '10px', fontWeight: 400, mb: '5px', ml: 0, pl: 0, children: facilityRoleButton.facilityName }), _jsx(RadioGroup, { onChange: (value) => onSelectFacilityRole(value, facilityRoleButton), value: facilityRoleButton.facilityRole, defaultValue: undefined, children: _jsxs(Stack, { direction: 'row', children: [_jsx(Radio, { colorScheme: 'orange', pr: '18px', value: FacilityRoleEnum.VISITOR }), _jsx(Radio, { colorScheme: 'orange', pr: '20px', value: FacilityRoleEnum.MEMBER }), _jsx(Radio, { colorScheme: 'orange', pr: '5px', value: FacilityRoleEnum.NONE })] }) })] }, facilityRoleButton.facilityIdentity));
        });
    };
    return (_jsxs(_Fragment, { children: [_jsxs(FormLabel, { pt: '10px', children: ["FCA Facilities (Permissions)", ' ', _jsxs("span", { className: 'required', style: { color: appColors.DELETE_ERROR }, children: [' ', "*"] })] }), _jsxs(Box, { p: '0px 10px 0px 10px', display: 'flex', w: '100%', justifyContent: 'end', children: [_jsx(Text, { fontSize: '10px', mr: '7px', children: FacilityRoleEnum.VISITOR }), _jsx(Text, { fontSize: '10px', children: FacilityRoleEnum.MEMBER }), _jsx(Text, { fontSize: '10px', ml: '7px', children: FacilityRoleEnum.NONE })] }), _jsx(Box, { p: '10px', children: renderRadioGroup() })] }));
};
