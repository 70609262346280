import { combineReducers, configureStore, } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react'; //not sure if this is needed, copied it from viduals
import lookupsReducer from './lookupsSlice';
import sidebarReducer from './sidebarSlice';
const rootReducer = combineReducers({
    sidebar: sidebarReducer,
    lookups: lookupsReducer,
});
export const store = setupStore();
export function setupStore(preloadedState) {
    return configureStore({
        reducer: rootReducer,
        preloadedState,
    });
}
setupListeners(store.dispatch);
