import { convertSimpleObjectListToFormDataArray } from '../../conversion/FormDataConversions';
import { stringToInt } from '../../conversion/NumberConversions';
import { FriendlyYesNoValues, } from './FacilityInterfaces';
export const convertSizeTypeToFriendlyPropertySizes = (sizeType) => {
    switch (sizeType) {
        case 'Units':
            return '# Units';
        case 'Keys':
            return '# Keys';
        default:
            return 'Sq Ft';
    }
};
export const resolvePropertySizeType = (facility) => {
    var _a, _b, _c;
    switch (facility.primaryFacilityType) {
        case 'Hospitality':
            return {
                value: (_a = facility.keyCount) !== null && _a !== void 0 ? _a : 0,
                type: '# Keys',
            };
        case 'Housing':
            return {
                value: (_b = facility.unitCount) !== null && _b !== void 0 ? _b : 0,
                type: '# Units',
            };
        default:
            return {
                value: (_c = facility.originalSquareFootage) !== null && _c !== void 0 ? _c : 0,
                type: 'Sq Ft',
            };
    }
};
export const resolvePropertySize = (facility, value) => {
    switch (facility.primaryFacilityType) {
        case 'Hospitality':
            return {
                value: value !== null && value !== void 0 ? value : 0,
                type: '# Keys',
            };
        case 'Housing':
            return {
                value: value !== null && value !== void 0 ? value : 0,
                type: '# Units',
            };
        default:
            return {
                value: value !== null && value !== void 0 ? value : 0,
                type: 'Sq Ft',
            };
    }
};
export const adjustPropertySize = (originalFacility, updateRequest) => {
    var _a, _b, _c;
    switch (originalFacility.primaryFacilityType) {
        case 'Hospitality':
            return {
                keyCount: (_a = updateRequest.propertySize) !== null && _a !== void 0 ? _a : originalFacility.keyCount,
            };
        case 'Housing':
            return {
                unitCount: (_b = updateRequest.propertySize) !== null && _b !== void 0 ? _b : originalFacility.unitCount,
            };
        default:
            return {
                originalSquareFootage: (_c = updateRequest.propertySize) !== null && _c !== void 0 ? _c : originalFacility.originalSquareFootage,
            };
    }
};
export const convertFacilityDetailResponseToIFacility = (facility) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
    return {
        identity: facility.facilityId,
        siteName: facility.siteName,
        name: facility.name,
        address1: facility.location.address1,
        address2: (_a = facility.location.address2) !== null && _a !== void 0 ? _a : undefined,
        city: facility.location.city,
        stateOrProvince: facility.location.stateOrProvince,
        postalCode: facility.location.postalCode,
        latitude: facility.location.latitude,
        longitude: facility.location.longitude,
        photoName: (_c = (_b = facility.photo) === null || _b === void 0 ? void 0 : _b.fileName) !== null && _c !== void 0 ? _c : undefined,
        photoUrl: (_e = (_d = facility.photo) === null || _d === void 0 ? void 0 : _d.photoUrl) !== null && _e !== void 0 ? _e : undefined,
        yearBuilt: facility.yearBuilt,
        primaryFacilityType: facility.primaryFacilityType,
        secondaryFacilityType: (_f = facility.secondaryFacilityType) !== null && _f !== void 0 ? _f : undefined,
        tertiaryFacilityType: (_g = facility.tertiaryFacilityType) !== null && _g !== void 0 ? _g : undefined,
        originalSquareFootage: (_h = facility.originalSquareFootage) !== null && _h !== void 0 ? _h : undefined,
        keyCount: (_j = facility.keyCount) !== null && _j !== void 0 ? _j : undefined,
        unitCount: (_k = facility.unitCount) !== null && _k !== void 0 ? _k : undefined,
        propertySize: resolvePropertySizeType(facility),
        customerInternalId: (_l = facility.customerInternalId) !== null && _l !== void 0 ? _l : undefined,
        propertyManager: (_m = facility.propertyManager) !== null && _m !== void 0 ? _m : undefined,
        hasPropertySurvey: facility.hasPropertySurvey ? 'Yes' : 'No',
        hasCompletedAudit: facility.hasCompletedAudit ? 'Yes' : 'No',
        facilityGrouper1: (_o = facility.facilityGrouper1) !== null && _o !== void 0 ? _o : undefined,
        facilityGrouper2: (_p = facility.facilityGrouper2) !== null && _p !== void 0 ? _p : undefined,
        ownershipType: (_q = facility.ownershipType) !== null && _q !== void 0 ? _q : undefined,
        additions: facility.additions.map((addition) => convertFacilityAdditionResponseToIFacilityAddition(addition)),
        enrollment: facility.enrollmentYears.map(convertFacilityEnrollmentResponseToIFacilityEnrollment),
    };
};
export const convertFacilityAdditionResponseToIFacilityAddition = (addition) => {
    return {
        identity: addition.additionId,
        yearBuilt: addition.yearBuilt,
        squareFootage: addition.squareFootage,
    };
};
export const convertFacilityEnrollmentResponseToIFacilityEnrollment = (enrollment) => {
    return {
        schoolYear: enrollment.schoolYear,
        gradeEnrollment: enrollment.gradeEnrollment.map((grade) => convertGradeEnrollmentResponseToIGradeEnrollment(grade)),
    };
};
const convertGradeEnrollmentResponseToIGradeEnrollment = (gradeEnrollment) => {
    return {
        gradeLevel: gradeEnrollment.gradeLevel,
        numberOfStudents: gradeEnrollment.count,
    };
};
export const convertFacilityTemplateResponseToIFacilityTemplate = (template) => {
    return {
        primaryFacilityTypes: template.primaryFacilityTypes.map(convertPrimaryFacilityTypeResponseToIFacilityType),
        secondaryFacilityTypes: template.secondaryFacilityTypes.map(convertFacilityTypeResponseToIFacilityType),
        tertiaryFacilityTypes: template.tertiaryFacilityTypes.map(convertFacilityTypeResponseToIFacilityType),
        schoolYears: template.schoolYears,
        gradeLevels: template.gradeLevels,
        ownershipTypes: template.ownershipTypes,
        importTemplateUrl: template.facilityTemplateUrl,
        siteNames: template.siteNames,
    };
};
const convertPrimaryFacilityTypeResponseToIFacilityType = (facilityType) => {
    return {
        identity: facilityType.facilityTypeId,
        name: facilityType.facilityTypeName,
        sizeType: convertSizeTypeToFriendlyPropertySizes(facilityType.sizeType),
    };
};
const convertFacilityTypeResponseToIFacilityType = (facilityType) => {
    return {
        identity: facilityType.facilityTypeId,
        name: facilityType.facilityTypeName,
        parentFacilityTypeId: facilityType.parentFacilityTypeId,
    };
};
/** Only used for PIQ facility edit conversions look to clean up when applying to PIQ*/
export const convertIUpdateFacilityToIFacility = (facility, originalFacility) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u;
    return Object.assign(Object.assign({}, originalFacility), { name: (_a = facility.name) !== null && _a !== void 0 ? _a : originalFacility.name, address1: (_b = facility.address1) !== null && _b !== void 0 ? _b : originalFacility.address1, address2: (_c = facility.address2) !== null && _c !== void 0 ? _c : originalFacility.address2, city: (_d = facility.city) !== null && _d !== void 0 ? _d : originalFacility.city, stateOrProvince: (_e = facility.stateOrProvince) !== null && _e !== void 0 ? _e : originalFacility.stateOrProvince, postalCode: (_f = facility.postalCode) !== null && _f !== void 0 ? _f : originalFacility.postalCode, yearBuilt: (_g = facility.yearBuilt) !== null && _g !== void 0 ? _g : originalFacility.yearBuilt, note: (_h = facility.note) !== null && _h !== void 0 ? _h : originalFacility.note, enrollment: (_j = facility.enrollment) !== null && _j !== void 0 ? _j : originalFacility.enrollment, primaryFacilityType: (_k = facility.primaryFacilityType) !== null && _k !== void 0 ? _k : originalFacility.primaryFacilityType, secondaryFacilityType: (_l = facility.secondaryFacilityType) !== null && _l !== void 0 ? _l : originalFacility.secondaryFacilityType, tertiaryFacilityType: (_m = facility.tertiaryFacilityType) !== null && _m !== void 0 ? _m : originalFacility.tertiaryFacilityType, customerInternalId: (_o = facility.customerInternalId) !== null && _o !== void 0 ? _o : originalFacility.customerInternalId, propertyManager: (_p = facility.propertyManager) !== null && _p !== void 0 ? _p : originalFacility.propertyManager, ownershipType: (_q = facility.ownershipType) !== null && _q !== void 0 ? _q : originalFacility.ownershipType, hasPropertySurvey: ((_r = facility.hasPropertySurvey) !== null && _r !== void 0 ? _r : originalFacility.hasPropertySurvey)
            ? 'Yes'
            : 'No', hasCompletedAudit: ((_s = facility.hasCompletedAudit) !== null && _s !== void 0 ? _s : originalFacility.hasCompletedAudit)
            ? 'Yes'
            : 'No', facilityGrouper1: (_t = facility.facilityGrouper1) !== null && _t !== void 0 ? _t : originalFacility.facilityGrouper1, facilityGrouper2: (_u = facility.facilityGrouper2) !== null && _u !== void 0 ? _u : originalFacility.facilityGrouper2 });
};
export const convertFacilitySummaryResponseToIFacilitySummary = (facility) => {
    var _a, _b, _c;
    return {
        identity: facility.facilityId,
        name: facility.name,
        siteName: facility.siteName,
        location: convertFacilityLocationResponseToILocation(facility.location),
        isRemovable: facility.isRemovable,
        photoUrl: (_a = facility.photoUrl) !== null && _a !== void 0 ? _a : undefined,
        totalSquareFootage: (_b = facility.totalSize) !== null && _b !== void 0 ? _b : undefined,
        latestEnrolledCount: (_c = facility.latestEnrolledCount) !== null && _c !== void 0 ? _c : undefined,
        yearBuilt: facility.yearBuilt,
    };
};
export const convertFacilityLocationResponseToILocation = (location) => {
    var _a;
    return {
        address1: location.address1,
        address2: (_a = location.address2) !== null && _a !== void 0 ? _a : undefined,
        city: location.city,
        stateOrProvince: location.stateOrProvince,
        postalCode: location.postalCode,
        latitude: location.latitude,
        longitude: location.longitude,
    };
};
export const convertIFacilityToFacilityForm = (facility) => {
    var _a;
    return {
        name: (facility === null || facility === void 0 ? void 0 : facility.name) || '',
        siteName: (facility === null || facility === void 0 ? void 0 : facility.siteName) || '',
        address1: (facility === null || facility === void 0 ? void 0 : facility.address1) || '',
        address2: (facility === null || facility === void 0 ? void 0 : facility.address2) || '',
        city: (facility === null || facility === void 0 ? void 0 : facility.city) || '',
        stateOrProvince: (facility === null || facility === void 0 ? void 0 : facility.stateOrProvince) || '',
        postalCode: (facility === null || facility === void 0 ? void 0 : facility.postalCode) || '',
        ownershipType: (_a = facility === null || facility === void 0 ? void 0 : facility.ownershipType) !== null && _a !== void 0 ? _a : '',
        primaryFacilityType: (facility === null || facility === void 0 ? void 0 : facility.primaryFacilityType) || '',
        secondaryFacilityType: (facility === null || facility === void 0 ? void 0 : facility.secondaryFacilityType) || '',
        tertiaryFacilityType: (facility === null || facility === void 0 ? void 0 : facility.tertiaryFacilityType) || '',
        propertySize: (facility === null || facility === void 0 ? void 0 : facility.propertySize.value.toString()) || '',
        propertyType: (facility === null || facility === void 0 ? void 0 : facility.propertySize.type) || 'Sq Ft',
        yearBuilt: (facility === null || facility === void 0 ? void 0 : facility.yearBuilt) ? facility.yearBuilt.toString() : '',
        additions: (facility === null || facility === void 0 ? void 0 : facility.additions)
            ? convertIFacilityAdditionToIConfigureAdditionForm(facility === null || facility === void 0 ? void 0 : facility.additions)
            : [],
    };
};
export const convertIFacilityAdditionToIConfigureAdditionForm = (additions) => {
    return additions.map((addition) => {
        return {
            identity: addition.identity,
            yearBuilt: addition.yearBuilt.toString(),
            squareFootage: addition.squareFootage.toString(),
        };
    });
};
export const convertFacilityFormToIConfigureFacility = (formData, facilityIdentity) => {
    const configureFacility = {
        identity: facilityIdentity,
        name: formData.name,
        siteName: formData.siteName,
        address1: formData.address1,
        address2: formData.address2,
        city: formData.city,
        stateOrProvince: formData.stateOrProvince,
        postalCode: formData.postalCode,
        yearBuilt: stringToInt(formData.yearBuilt),
        primaryFacilityType: formData.primaryFacilityType,
        secondaryFacilityType: formData.secondaryFacilityType,
        tertiaryFacilityType: formData.tertiaryFacilityType,
        ownershipType: formData.ownershipType,
        additions: formData.additions
            ? convertIConfigureAdditionFormToIConfigureAddition(formData.additions)
            : [],
    };
    return Object.assign(Object.assign({}, configureFacility), convertPropertySize(parseInt(formData.propertySize), formData.propertyType));
};
const convertIConfigureAdditionFormToIConfigureAddition = (additions) => {
    return additions.map((addition) => {
        return {
            identity: addition.identity,
            yearBuilt: Number(addition.yearBuilt),
            squareFootage: Number(addition.squareFootage),
        };
    });
};
const convertPropertySize = (propertySize, propertyType) => {
    switch (propertyType) {
        case '# Keys':
            return {
                keyCount: propertySize,
                unitCount: undefined,
                originalSquareFootage: undefined,
            };
        case '# Units':
            return {
                keyCount: undefined,
                unitCount: propertySize,
                originalSquareFootage: undefined,
            };
        default:
            return {
                keyCount: undefined,
                unitCount: undefined,
                originalSquareFootage: propertySize,
            };
    }
};
export const convertIConfigureFacilityToFacilityFormData = (facility, orgIdentity) => {
    var _a, _b, _c, _d;
    const formRequest = {
        FacilityId: facility.identity,
        Name: facility.name,
        OrganizationId: orgIdentity,
        SiteName: facility.siteName,
        Address1: facility.address1,
        Address2: facility.address2,
        City: facility.city,
        StateOrProvince: facility.stateOrProvince,
        PostalCode: facility.postalCode,
        YearBuilt: facility.yearBuilt,
        PrimaryFacilityType: facility.primaryFacilityType,
        SecondaryFacilityType: facility.secondaryFacilityType,
        TertiaryFacilityType: facility.tertiaryFacilityType,
        OwnershipType: facility.ownershipType,
        OriginalSquareFootage: facility.originalSquareFootage,
        KeyCount: facility.keyCount,
        UnitCount: facility.unitCount,
        CustomerInternalId: facility.customerInternalId,
        PropertyManager: facility.propertyManager,
        HasPropertySurvey: ((_a = facility.hasPropertySurvey) === null || _a === void 0 ? void 0 : _a.toString()) === FriendlyYesNoValues.Yes,
        HasCompletedAudit: ((_b = facility.hasCompletedAudit) === null || _b === void 0 ? void 0 : _b.toString()) === FriendlyYesNoValues.Yes,
        FacilityGrouper1: facility.facilityGrouper1,
        FacilityGrouper2: facility.facilityGrouper2,
        'FacilityPhoto.FileName': (_c = facility.facilityPhoto) === null || _c === void 0 ? void 0 : _c.fileName,
        'FacilityPhoto.File': (_d = facility.facilityPhoto) === null || _d === void 0 ? void 0 : _d.file, //4182: adjust as part of adding logo card
    };
    const additions = convertSimpleObjectListToFormDataArray('Additions', facility.additions || []);
    const enrollmentYears = convertSimpleObjectListToFormDataArray('EnrollmentYears', facility.enrollment || []);
    return Object.assign(Object.assign({}, formRequest), { additions, enrollmentYears });
};
export const convertIFacilitytoIFacilitySummary = (facilityToUpdate, updatedFacility) => {
    var _a, _b;
    const latestEnrolledCount = (_b = (_a = updatedFacility.enrollment) === null || _a === void 0 ? void 0 : _a.slice().sort((a, b) => {
        const [startYearA] = a.schoolYear.split('-').map(Number);
        const [startYearB] = b.schoolYear.split('-').map(Number);
        return startYearB - startYearA;
    })[0]) === null || _b === void 0 ? void 0 : _b.gradeEnrollment.reduce((total, grade) => total + grade.numberOfStudents, 0);
    return {
        identity: updatedFacility.identity,
        name: updatedFacility.name,
        siteName: updatedFacility.siteName,
        location: {
            address1: updatedFacility.address1,
            address2: updatedFacility.address2,
            city: updatedFacility.city,
            stateOrProvince: updatedFacility.stateOrProvince,
            postalCode: updatedFacility.postalCode,
            latitude: updatedFacility.latitude,
            longitude: updatedFacility.longitude,
        },
        photoUrl: updatedFacility.photoUrl,
        totalSquareFootage: updatedFacility.originalSquareFootage,
        latestEnrolledCount: latestEnrolledCount,
        isRemovable: facilityToUpdate ? facilityToUpdate.isRemovable : true,
        yearBuilt: updatedFacility.yearBuilt,
    };
};
