import { IAppNames } from '../App';
import { convertOrgContactToIUserContact } from '../User/UserConversions';
export const convertOrganizationSummaryResponseToOrganizationSummary = (org) => {
    var _a;
    const appNames = Object.values(IAppNames);
    return {
        identity: org.organizationIdentity,
        name: org.name,
        primaryContact: convertOrgContactToIUserContact(org.primaryContact),
        apps: appNames.map((app) => convertAppToIApp(app, org.apps)),
        logoUrl: (_a = org.logoUrl) !== null && _a !== void 0 ? _a : undefined,
    };
};
export const convertOrganizationResponseToIOrganizationDetails = (org) => {
    const appNames = Object.values(IAppNames);
    return {
        identity: org.organizationIdentity,
        name: org.name,
        businessUnit: org.businessUnit,
        companyWebsite: org.companyWebsite,
        logo: org.logo
            ? convertOrganizationLogoResponseToIOrganizationLogo(org.logo)
            : undefined,
        verticalMarket: org.verticalMarket,
        primaryContact: convertOrgContactToIUserContact(org.primaryContact),
        apps: appNames.map((app) => convertAppToIApp(app, org.apps)),
        sites: [],
        userCount: org.userCount,
    };
};
export const convertAppToIApp = (app, apps) => {
    const currentApp = apps.find((a) => a.app === app);
    const expiresOnDate = (currentApp === null || currentApp === void 0 ? void 0 : currentApp.expiresOn)
        ? currentApp.expiresOn
        : undefined;
    return {
        app: app,
        enabled: (currentApp === null || currentApp === void 0 ? void 0 : currentApp.isActive) || false,
        expiresOn: expiresOnDate,
    };
};
export const convertOrganizationFormToIUpsertOrganization = (org, image, logoName) => {
    return {
        nameOfOrg: org.nameOfOrg,
        logoFile: image,
        logoName: logoName,
        businessUnit: org.businessUnit,
        verticalMarket: org.verticalMarket,
        companyWebsite: org.companyWebsite,
        primaryContactId: org.primaryContactId,
        apps: org.apps,
    };
};
export const convertOrganizationToRequest = (org, orgIdentity) => {
    const formRequest = {
        OrganizationId: orgIdentity,
        Name: org.nameOfOrg,
        BusinessUnit: org.businessUnit,
        CompanyWebsite: org.companyWebsite,
        'Logo.LogoFile': org.logoFile,
        'Logo.FileName': org.logoName,
        VerticalMarket: org.verticalMarket,
        PrimaryContactId: org.primaryContactId,
    };
    org.apps.forEach((app, index) => {
        const appRequest = convertIAppToOrganizationAppRequest(app);
        formRequest[`Apps[${index}].app`] = appRequest.app;
        formRequest[`Apps[${index}].isActive`] = appRequest.isActive;
        formRequest[`Apps[${index}].expiresOn`] = appRequest.expiresOn;
    });
    return formRequest;
};
const convertIAppToOrganizationAppRequest = (app) => {
    return {
        app: app.app,
        isActive: app.enabled,
        expiresOn: app.expiresOn !== '' ? app.expiresOn : undefined,
    };
};
export const convertIOrganizationDetailsToIOrganizationSummary = (org) => {
    var _a, _b;
    return {
        identity: org.identity,
        name: org.name,
        primaryContact: org.primaryContact,
        apps: org.apps,
        logoUrl: (_b = (_a = org.logo) === null || _a === void 0 ? void 0 : _a.url) !== null && _b !== void 0 ? _b : undefined,
    };
};
export const convertOrganizationLogoResponseToIOrganizationLogo = (orgLogo) => {
    return {
        name: orgLogo.logoFileName,
        url: orgLogo.logoUrl,
    };
};
export const convertOrganizationTemplateResponseToIOrganizationInitial = (orgInitial) => {
    return {
        verticalMarkets: orgInitial.verticalMarkets,
        appContexts: orgInitial.appContexts,
    };
};
